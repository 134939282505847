import { FC, useEffect, useMemo, useState } from 'react'
import { updateInvoices } from '../utils/api';
import { toast } from 'react-toastify';
import { useOrganization } from '../contexts/OrganizationContext';
import { useFirebaseQuery } from '../hooks/firebaseHooks';
import { OngoingInvoice } from '../models/shared/OngoingInvoice';
import { COLLECTIONS } from '../utils/shared/constants';
import { GoodsOwner } from '../models/shared/GoodsOwner';
import InvoiceTable from '../components/InvoiceTable';
import { Spinner } from 'react-bootstrap';

interface IProps { }

/**
* @author
* @function @TestPage
**/

interface InvoiceGroup {
    goodsOwner: GoodsOwner,
    invoices: OngoingInvoice[],
}

export const InvoicesPage: FC<IProps> = (props) => {
    const organization = useOrganization();
    const [processing, setProcessing] = useState(false);
    const [invoices] = useFirebaseQuery<OngoingInvoice>(`${COLLECTIONS.ORGANIZATIONS}/${organization.id}/${COLLECTIONS.INVOICES}`);

    useEffect(() => {
        const asyncEffect = async () => {
            setProcessing(true);
            try {
                await updateInvoices();
            } catch (error) {
                console.error(error);
                toast.error('Failed to update invoices');
            }
            setProcessing(false);
        }
        asyncEffect();
    }, [])


    const invoiceGroups = useMemo(() => {
        const groups: InvoiceGroup[] = [];
        for (const goodsOwner of organization.goodsOwners) {
            const invoicesForOwner = invoices.filter(invoice => invoice.goodsOwner.id === goodsOwner.id);
            groups.push({ goodsOwner, invoices: invoicesForOwner });
        }

        return groups

    }, [invoices, organization.goodsOwners]);


    return (
        <>
            <h2 className='mb-4'>Invoices {processing && <Spinner />}</h2>

            {invoiceGroups.map((group, ix) => <InvoiceTable key={ix} goodsOwner={group.goodsOwner} invoices={group.invoices} />)}
        </>

    )
}
