export enum QUERY_PARAMS {
    EMAIL_SIGNIN_FINISHED = 'email_signin_finished',
    CODE = 'code',
}

export enum LOCAL_STORAGE_KEYS {
    MAGIC_LINK_EMAIL = 'magic_link_email',
}

export enum PATHS {

}

export enum COLLECTIONS {
    CONFIG = 'config',
    ORGANIZATIONS = 'organizations',
    SECRETS = 'secrets',
    INVOICES = 'invoices',
}

export enum SECRET_KEYS {
    AZURE = 'azure',
    GENERAL = 'general',
}

export const SUPPORT_EMAIL = 'support@beaver.codes';

export const ORGANIZATION_ID = 'agna';

