import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import useQueryParam from '../hooks/useQueryParams'
import { handleAzureCode } from '../utils/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface Props { }

function AzureLoginHandlerPage(props: Props) {
    const { code } = useQueryParam();
    const navigate = useNavigate();

    useEffect(() => {
        if (!code) {
            return;
        }

        const asyncEffect = async () => {
            try {
                await handleAzureCode(code);
                toast.success('Successfully logged in');
                navigate('/');
            } catch (e) {
                console.error(e);
            }

        }

        asyncEffect();
    }, [code, navigate])

    return <div>
        <h3>Logging in ...</h3>

        <Spinner />

    </div>
}

export default AzureLoginHandlerPage
